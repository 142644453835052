import vrlsr_img from "../../assets/img/products/VRLifeSavingRules.jpg";
import ia_img from "../../assets/img/products/interactive-animations.png";
import ocp_img from "../../assets/img/products/Organisational change projects.jpg";
import pd_img from "../../assets/img/products/Pre-job discussions.jpg";
import ptw_img from "../../assets/img/products/Permit to Work.jpg";
import sdt_img from "../../assets/img/products/Shut Down Training.jpg";
import sm_img from "../../assets/img/products/Safety Meetings.jpg";
import ilfi_img from "../../assets/img/products/iLFI.jpg";
import ilsr_img from "../../assets/img/products/iLifeSavingRules.jpg";
import fc_img from "../../assets/img/products/Flip Cards.jpg";

const products = [
  {
    id: "vr-life-saving-rules",
    title: "VRLifeSavingRules<sup>®</sup>",
    subtitle: "An ideal way to convince workers",
    category: ["Games", "Life Saving Rules", "Training"],
    img: vrlsr_img,
  },
  {
    id: "interactive-animations",
    title: "Interactive animations",
    subtitle: "Check your knowledge",
    category: [
      "Animations",
      "Apps",
      "Games",
      "Learning from Incidents",
      "Life Saving Rules",
    ],
    img: ia_img,
  },
  {
    id: "organisational-change-projects",
    title: "Organisational change projects",
    subtitle: "Program to change culture",
    category: ["Culture Change", "Learning from Incidents"],
    img: ocp_img,
  },
  {
    id: "pre-job-discussions",
    title: "Pre-job discussions",
    subtitle: "Create discussions",
    category: ["Animations", "Training"],
    img: pd_img,
  },
  {
    id: "permit-to-work",
    title: "Permit to Work",
    subtitle: "Inform about hazards",
    category: ["Animations", "Learning from Incidents", "Training"],
    img: ptw_img,
  },
  {
    id: "shut-down-training",
    title: "Shut Down Training",
    subtitle: "Train workers in hazards",
    category: ["Animations", "Training"],
    img: sdt_img,
  },
  {
    id: "safety-meetings",
    title: "Safety Meetings",
    subtitle: "Create discussions",
    category: ["Animations", "Training"],
    img: sm_img,
  },
  {
    id: "ilfi",
    title: "iLFI<sup>®</sup>",
    subtitle: "Learn from past incidents",
    category: [
      "Animations",
      "Apps",
      "Culture Change",
      "Learning from Incidents",
    ],
    img: ilfi_img,
  },
  {
    id: "ilife-saving-rules",
    title: "iLifeSavingRules<sup>®</sup>",
    subtitle: "Revolutionairy change in safety",
    category: ["Apps", "Culture Change", "Games", "Life Saving Rules"],
    img: ilsr_img,
  },
  {
    id: "flip-cards",
    title: "Flip Cards",
    subtitle: "Train workers in rules",
    category: ["Apps", "Games"],
    img: fc_img,
  },
];

export default products;
