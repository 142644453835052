import React, { useState } from "react";
import { Link } from "gatsby";
import products from "../json/products";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      vrlifesavingrules: file(
        relativePath: { eq: "products/VRLifeSavingRules.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      interactiveanimations: file(
        relativePath: { eq: "products/interactive-animations.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      organisationalchangeprojects: file(
        relativePath: { eq: "products/Organisational change projects.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      prejobdiscussions: file(
        relativePath: { eq: "products/Pre-job discussions.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      permittowork: file(relativePath: { eq: "products/Permit to Work.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      shutdowntraining: file(
        relativePath: { eq: "products/Shut Down Training.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      safetymeetings: file(
        relativePath: { eq: "products/Safety Meetings.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ilfi: file(relativePath: { eq: "products/iLFI.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ilifesavingrules: file(
        relativePath: { eq: "products/iLifeSavingRules.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      flipcards: file(relativePath: { eq: "products/Flip Cards.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  const [prodCat, setProdCat] = useState("All");
  const [prodList, setProdList] = useState(products);

  const handleClick = (link) => {
    setProdCat(link);

    if (link === "All") {
      setProdList(products);
    } else {
      setProdList(products.filter((data) => data.category.includes(link)));
    }
  };

  function createMarkup(title) {
    return {
      __html: title,
    };
  }

  return (
    <section className={`main-container products`}>
      <div className='row top-pad bottom-pad fd-col'>
        <div className='column fd-col center'>
          <h2
            className='main-title light no-mar'
            data-aos={`fade-up`}
            data-aos-duration='1000'
          >
            Our Products
          </h2>
          <nav
            className='product-links'
            data-aos={`fade-up`}
            data-aos-duration='1000'
          >
            <ul className='product-links-ul'>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "All" && "active"
                  }`}
                  to='#!'
                  onClick={() => handleClick("All")}
                >
                  All Products
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Animations" && "active"
                  }`}
                  to='#!'
                  onClick={() => handleClick("Animations")}
                >
                  Animations
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Apps" && "active"
                  }`}
                  onClick={() => handleClick("Apps")}
                  to='#!'
                >
                  Apps
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Culture Change" && "active"
                  }`}
                  onClick={() => handleClick("Culture Change")}
                  to='#!'
                >
                  Culture Change
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Games" && "active"
                  }`}
                  onClick={() => handleClick("Games")}
                  to='#!'
                >
                  Games
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Learning from Incidents" && "active"
                  }`}
                  onClick={() => handleClick("Learning from Incidents")}
                  to='#!'
                >
                  Learning from Incidents
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Life Saving Rules" && "active"
                  }`}
                  onClick={() => handleClick("Life Saving Rules")}
                  to='#!'
                >
                  Life Saving Rules
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Training" && "active"
                  }`}
                  onClick={() => handleClick("Training")}
                  to='#!'
                >
                  Training
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div
          className={`column fd-col product-list ${prodCat
            .toLowerCase()
            .replace(/ /g, "-")}`}
        >
          {prodList.map((el) => {
            const imgId = el.id.replace(/-/g, "");
            const imgSrc = getImage(data[imgId].childImageSharp);
            return (
              <div
                className={`product-container`}
                key={el.id}
                data-aos='flip-up'
                data-aos-duration='600'
                // data-aos-delay='500'
              >
                <div className='image-container'>
                  <Link to={`/products/${el.id}`}>
                    {/* <img src={el.img} alt={el.title} /> */}
                    <GatsbyImage image={imgSrc} alt={el.title} />
                  </Link>
                  <div className='learn-more'>Learn More</div>
                </div>
                <Link to={`/products/${el.id}`}>
                  <h3
                    className='main-subtitle no-mar'
                    dangerouslySetInnerHTML={createMarkup(el.title)}
                  />
                </Link>
                <p className='light no-mar'>{el.subtitle}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Products;
