import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import video from "../assets/video/video.mp4";
import visionImg from "../assets/img/icons/vision.gif";
import missionImg from "../assets/img/icons/mission.gif";
import commitmentImg from "../assets/img/icons/commitment.gif";
import { Link } from "gatsby";
import Clients from "../components/common/Clients";
import Products from "../components/products/Products";
import InteractiveVideo from "../components/common/InteractiveVideo";

import { isMobile, isTablet } from "react-device-detect";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { TweenLite, Power3 } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

const Video = ({ handleClose }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  let hiddenTxt = useRef();

  useEffect(() => {
    hiddenTxt.current.focus();
  }, []);

  return (
    <div
      className='video-container'
      data-aos='zoom-in'
      role='button'
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={hiddenTxt}
    >
      <iframe
        width='100%'
        height='100%'
        src='https://www.youtube.com/embed/_eVVzFfwVSE?rel=0&autoplay=1&modestbranding=1&controls=1&loop=1&showinfo=0'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        aria-hidden
        onMouseEnter={() => hiddenTxt.current.focus()}
        onMouseMove={() => hiddenTxt.current.focus()}
        onClick={() => hiddenTxt.current.focus()}
      ></iframe>

      <button className='small' onClick={handleClose}>
        Close Video
      </button>
    </div>
  );
};

const IndexPage = () => {
  const [offset, setOffset] = useState(0);
  const [hideLoading, setHideLoading] = useState(false);
  const state = useContext(GlobalStateContext);
  let slideTitle = useRef(null);
  let slideDesc = useRef(null);
  let slideButton = useRef(null);
  let slideCol = useRef(null);
  const [showVid, setShowVid] = useState(false);
  let vidRef = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      TweenLite.to(slideTitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeInOut,
        delay: 1,
      });
      TweenLite.to(slideDesc, 1, {
        opacity: 1,
        x: 0,
        ease: Power3.easeInOut,
        delay: 1.5,
      });
      TweenLite.to(slideButton, 1, {
        opacity: 1,
        x: 0,
        ease: Power3.easeInOut,
        delay: 2.5,
      });
      TweenLite.to(slideCol, 1.5, {
        backgroundColor: "rgba(0,0,0,.5)",
        x: 0,
        ease: Power3.easeInOut,
        delay: 2.5,
      });

      vidRef.play()
    }
  }, [state.showPageLoading]);

  useEffect(() => {
    updateOffset();
    window.addEventListener("scroll", updateOffset);
    setHideLoading(true);
    return () => window.removeEventListener("scroll", updateOffset);
  }, []);

  const updateOffset = () => {
    setOffset(window.pageYOffset);
  };

  const handleCloseVid = () => {
    setShowVid(false);
  };

  return (
    <Fragment>
      {showVid && <Video handleClose={handleCloseVid} />}
      <Layout headerTransparent isHome>
        <Seo
          title='VIRSAT | Virtual Reality Safety Training'
          description='VIRSAT - Virtual Reality Safety Training is a company with expertise in HSE, Learning, IT and gaming solutions for businesses. Consult us now!'
        />
        {!state.showPageLoading && hideLoading && (
          <div className={`scroll-down ${offset > 70 && "hideme"}`}>
            <span></span>
            <span></span>
          </div>
        )}

        {/* Home Slider */}
        <section className='main-container home-slider'>
          <video playsInline muted loop ref={(el) => (vidRef = el)}>
            <source src={video} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <div className='row'>
            <div className='column fd-col' ref={(el) => (slideCol = el)}>
              <h1 className='slide-title' ref={(el) => (slideTitle = el)}>
                <span className='virtual-reality'>Virtual Reality</span>
                <br />
                Safety Training
              </h1>
              <p className='slide-desc' ref={(el) => (slideDesc = el)}>
                VIRSAT is a company with expertise in HSE, Learning, IT and
                gaming solutions for businesses. VIRSAT has a track record of
                implementing advanced solutions in companies IT systems. We use
                novel tools to improve safety in the business.
              </p>
              <div
                className='button-container'
                ref={(el) => (slideButton = el)}
              >
                <Link className='button-link medium' to='/contact/'>
                  Contact Us
                </Link>
                <button
                  className='transparent-w-border medium'
                  onClick={() => setShowVid(true)}
                >
                  Watch Video
                </button>
              </div>
            </div>
          </div>
        </section>
        
        {!state.showPageLoading && (
          <Fragment>
          {/* Home About */}
          <section className='main-container home-about'>
            <div className='row fd-col top-pad bottom-pad'>
              <div
                className='column fd-col center'
                data-aos='fade-in'
                data-aos-duration='1000'
              >
                <h2 className='main-subtitle large'>
                  Virtual Reality Safety Training helps
                  <br />
                  <span className='main-title remove-br-mobile'>
                    companies to make significant steps
                    <br />
                    &nbsp;in safety performance
                  </span>
                </h2>
              </div>
              <hr
                className='site-border'
                id='site-border'
                data-aos='fade-in'
                data-aos-duration='1000'
              />
              <div
                className='column details-image show-overflow'
                data-aos={`fade-in`}
                data-aos-duration='1000'
                data-aos-delay='300'
              >
                <div
                  className='details'
                >
                  <p>
                    Virtual Reality Safety Training (VIRSAT) is a company focused on developing new educational tools that can be used to enhance the safety competence and behaviors of the front-line workers and their supervisors.  VIRSAT aims at providing the best available learning techniques to help companies to achieve a safer working environment. Virtual Reality is a new technology, which is able to achieve a revolution in new ways of learning.
                  </p>
                  <Link
                    className='button-link secondary'
                    to='/about/'
                  >
                    Learn more about us
                  </Link>
                </div>
                <div
                  className='image-container'
                  data-aos={`${isMobile || isTablet ? "zoom-up" : "fade-in"}`}
                  data-aos-duration='1000'
                  // data-aos-delay='200'
                  id='image-container'
                >
                  <StaticImage
                    src='../assets/img/pages/about-us-img.jpg'
                    alt='Virtual Reality Safety Training helps companies to make significant steps in safety performance'
                    placeholder='blurred'
                    // layout='constrained'
                    // height='300'
                    objectFit
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="main-container mission-vision primary-bg">
              <div className="row top-pad bottom-pad bottom-mar">
                <div className="column fd-col left-column">
                  <div>
                    <div className="mv-img-container" data-aos="zoom-in">
                      {/* <StaticImage
                        src='../assets/img/icons/mission.gif'
                        alt='VIRSAT Mission Icon'
                        placeholder='blurred'
                        objectFit
                      /> */}
                      <img src={missionImg} alt="VIRSAT Mission Icon"/>
                    </div>
                    <div data-aos="fade-in">
                      <h3 className="main-subtitle large mission">Mission</h3>
                      <p className="light">VIRSAT’s mission is to develop, produce and provide innovative, practical and proven learning methods to impact safety competence and human performance of workers in industry.</p>
                    </div>
                  </div>
                  <div>
                    <div className="mv-img-container vision" data-aos="zoom-in">
                      {/* <StaticImage
                        src='../assets/img/icons/vision.gif'
                        alt='VIRSAT Vision Icon'
                        placeholder='blurred'
                        objectFit
                      /> */}
                      <img src={visionImg} alt="VIRSAT Vision Icon"/>
                    </div>
                    <div data-aos="fade-in">
                      <h3 className="main-subtitle large vision">Vision</h3>
                      <p className="light">Reduce the number of workplace related incidents.</p>
                    </div>
                  </div>
                </div>
                <div className="column fd-col right-column">
                  <div>
                    <div className="mv-img-container commitment" data-aos="zoom-in">
                      {/* <StaticImage
                        src='../assets/img/icons/commitment.gif'
                        alt='VIRSAT Commitment Icon'
                        placeholder='blurred'
                        objectFit
                      /> */}
                      <img src={commitmentImg} alt="VIRSAT Commitment Icon"/>
                    </div>
                    <div data-aos="fade-in">
                      <h3 className="main-subtitle large commitment">Commitment</h3>
                      <h4 className="main-subtitle light first">Extensive Training Modules</h4>
                      <p className="light">Our solutions are developed with our customers in mind. Our modules are meant to adapt to different requirements across diverse industries. Through this, contractors of companies can also use the same training modules to ensure that their workers are fully aligned with the client’s safety measures.</p>

                      <h4 className="main-subtitle light">Results-driven Innovative Solutions</h4>
                      <p className="light">VIRSAT is focused on developing solutions that ensures performance and safety improvement of workers in high-risk working environment.</p>

                      <h4 className="main-subtitle light">Exceptional Customer Service</h4>
                      <p className="light">Helping our customers establish an effective safety standard for their company and actually having the workers observe the protocols at all times is our top priority.</p>
                    </div>
                  </div>
                </div>
              </div>
          </section>
          <Clients />
          <Products />
          <InteractiveVideo />
          </Fragment>
        )}
      </Layout>
    </Fragment>
  );
};

export default IndexPage;
